<template>
  <div class="head-bar-container" @click.self="closeMenu">
    <div class="head-bar-row pc_padding">
      <div class="head-bar-name-row cursor" @click="gohome">
        <div :class="`head-bar-name type-${domainConfig['designType']}`">
          {{ title }}
        </div>
      </div>
      <div class="head-bar-hamber-row cursor" @click="toggleOpenState">
        <img src="@/assets/menu.png" alt="">
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }">
      <div class="menu-title">
        Resources
      </div>
      <ul>
        <li class="cursor" @click="goto('about')">
          <a>About</a>
          <div class="arrow" />
        </li>
        <li class="cursor" @click="goto('terms')">
          <a>Terms of services</a>
          <div class="arrow" />
        </li>
        <li class="cursor" @click="goto('privacy')">
          <a>Privacy policy</a>
          <div class="arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      "domainConfig"
    ])
  },

  mounted () {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    toggleOpenState () {
      this.isOpen = !this.isOpen;
    },
    handleClickOutside (event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    closeMenu () {
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home') {
        this.$emit('setRequestDomainHome');
      }
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
  },
}
</script>
